import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { filterHtml } from "../utils/filterHtml";
import { PropTypes } from "prop-types";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { Image } from "../components/Slices/Image";

const Speaker = ({ data }) => {

  if (!data) return null;

  const page = data.prismicSpeaker || {};
  const { lang, type, url } = page || {};

  const title = page.data.title.text;
  const desc = page.data.description.text;
  const parents = [{
    url: page.data.event.document.url,
    label: page.data.event.document.data.title.text
  }]

  const alternateLanguages = page.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    title,
    desc,
    parents
  };

  return (
    <Layout activeDocMeta={activeDoc}>
      <div className="speaker">
        <div className="o-wrapper o-2-col -sidearea">
          <div>
            <h1 className="">{page.data.title.text}</h1>
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: filterHtml(page.data.description.html),
              }}
            />
          </div>
          <div className="is-sidearea speaker__sidearea">
            <Image className="speaker__image" image={page.data.image} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

Speaker.propTypes = {
  data: PropTypes.object,
};

export default withPrismicPreview(Speaker);

export const query = graphql`
  query SpeakerQuery($lang: String, $id: String, $locale: String) {
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicSpeaker(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      data {
        title {
          text
        }
        description {
          text
          html
        }
        event {
          document {
            ... on PrismicEvent {
              id
              url
              data {
                title {
                  text
                }
              }
            }
          }
        }
        image {
          url
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 600
                height: 600
                layout: CONSTRAINED
                placeholder: NONE
              )
            }
            publicURL
          }
        }
      }
      type
      url
      alternate_languages {
        document {
          ... on PrismicSpeaker {
            url
          }
        }
        lang
      }
      lang
    }
  }
`;
